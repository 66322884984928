var __extends = this && this.__extends || function () {
  var n = function (t, i) {
    n = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (n, t) {
      n.__proto__ = t;
    } || function (n, t) {
      for (var i in t) if (Object.prototype.hasOwnProperty.call(t, i)) n[i] = t[i];
    };
    return n(t, i);
  };
  return function (t, i) {
    if (typeof i !== "function" && i !== null) throw new TypeError("Class extends value " + String(i) + " is not a constructor or null");
    n(t, i);
    function r() {
      this.constructor = t;
    }
    t.prototype = i === null ? Object.create(i) : (r.prototype = i.prototype, new r());
  };
}();
import { r as randomString } from "./element-d66cb8c2.js";
var SubjectBase = function () {
  function n() {
    this.consumers = new Map();
  }
  return n;
}();
var CONSUMER_ID_LENGTH = 32;
var BehaviorSubject = function (n) {
  __extends(t, n);
  function t(t) {
    if (t === void 0) {
      t = undefined;
    }
    var i = n.call(this) || this;
    i.value = t;
    return i;
  }
  t.prototype.next = function (n, t) {
    if (t === void 0) {
      t = false;
    }
    var i = true;
    var r = this.value;
    if (!t) {
      try {
        i = JSON.stringify(n) !== JSON.stringify(this.value);
      } catch (n) {
        console.warn("Error occurred during publishing new value", n);
      }
    }
    if (i) {
      this.value = n;
      this.consumers.forEach(function (t) {
        return t === null || t === void 0 ? void 0 : t(n, r);
      });
    }
  };
  t.prototype.subscribe = function (n) {
    var t = this;
    var i = randomString(CONSUMER_ID_LENGTH);
    this.consumers.set(i, n);
    n === null || n === void 0 ? void 0 : n(this.value);
    return {
      id: i,
      unsubscribe: function () {
        return t.consumers.delete(i);
      }
    };
  };
  t.prototype.getValue = function () {
    return this.value;
  };
  return t;
}(SubjectBase);
var SubscriptionList = function () {
  function n() {
    this._list = {};
    return new Proxy(this, {
      get: function (n, t) {
        if (t in n) {
          return n[t];
        }
        if (t in n._list) {
          return n._list[t];
        }
        return null;
      },
      set: function (n, t, i) {
        n.unsubscribeSafe(t);
        n._list[t] = i;
        return true;
      }
    });
  }
  n.prototype.unsubscribeSafe = function (n) {
    if (this._list[n]) {
      this._list[n].unsubscribe();
    }
  };
  n.prototype.unsubscribeAllSafe = function () {
    for (var n in this._list) {
      this.unsubscribeSafe(n);
    }
  };
  return n;
}();
export { BehaviorSubject as B, SubjectBase as S, SubscriptionList as a };